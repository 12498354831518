import React from 'react';
import { Link } from 'react-router-dom';

function HomePagev2() {
  return (
    <div className='bg-bg h-full py-8 '>
      <div className='flex flex-col h-full xs:w-11/12 md:w-9/12 max-w-4xl  mx-auto gap-4 '>
        {/* Initial Card */}
        <div className=' bg-cardBg rounded-lg py-4 px-8 md:pt-8 md:pb-16 md:pl-10 md:pr-20 lg:pl-12 max-w-4xl'>
          <div className='flex flex-col-reverse justify-center items-center md:flex-row md:justify-start md:items-start gap-4'>
            <div className='md:hidden my-2 text-center'>
              <button className='bg-green  rounded-xl font-bold text-base px-10 py-2 lg:text-lg md:px-8 md:py-3 '>
                <Link to={'/mysteries'}>Earn</Link>
              </button>
            </div>
            <img
              src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/60fb0c2f-3713-4b36-30cd-ec9c2bf08500/profile'
              alt='coin'
              className='xs:h-[4.5rem] xs:w-[4.5rem]  md:h-20 md:w-20'
            />
            <div>
              <h2 className='font-bold text-2xl md:text-3xl lg:text-4xl'>
                Dr Scovy Tokens
              </h2>
              <h3 className='font-bold text-lgl md:text-xl lg:text-2xl'>
                Earn. Redeem. Happiness.
              </h3>
              <p className='mt-4'>
                Join the fun by earning tokens through Twitch raids, solving mystery videos, or sharing photos. Drop your tokens into the treat machine to send a snack to the ducks—then watch them enjoy it live at <a href='live.dashducks.tv'>live.dashducks.tv</a>!
              </p>
              <p className='mt-2'>
                <b>Bonus:</b> Keep collecting tokens to hatch your own ducklings!
              </p>
            </div>
          </div>
        </div>

        {/* Dashducks Card */}
        <div className=' bg-cardBg rounded-lg py-4 px-8 md:py-8 md:pl-10 md:pr-20 lg:pl-12 lg:pr-24 max-w-4xl'>
          <div className='flex flex-col-reverse justify-center items-center md:flex-row md:justify-between'>
            <div className='flex flex-col justify-center items-center md:justify-start md:items-start gap-1'>
              <h2 className='font-bold text-2xl md:text-3xl lg:text-4xl'>
                The DashDucks
              </h2>
              <h3 className='font-bold text-lg md:text-xl lg:text-2xl'>
                Watch. Relax. Feed. Smile.
              </h3>

              <Link
                to='https://live.dashducks.tv'
                target='_blank'
                className='text-[#516BF2] font-bold text-base lg:text-xl hover:underline'
              >
                DashDucks Livestream
              </Link>
            </div>
            <div className='relative xs:w-24 sm:w-30 md:w-36 lg:w-40'>
              <img
                src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/609272bc-f0bb-407d-81eb-1cf057057200/public'
                alt='kwek_duck'
                className='xs:w-24 sm:w-30 md:w-36 lg:w-40 md:absolute right-0 bottom-[-70px]'
              />
            </div>
          </div>
        </div>

        {/* Mysteries Display Card */}
        <div className=' bg-cardBg rounded-lg py-4 px-8 md:pt-8 md:pb-20 md:pl-10 md:pr-20 lg:pl-12 lg:pr-24 max-w-4xl'>
          <div className='flex flex-col-reverse justify-center items-center md:flex-row md:justify-between'>
            <div className='flex flex-col justify-center items-center md:justify-start md:items-start gap-1'>
              <h2 className='font-bold text-2xl md:text-3xl lg:text-4xl'>
                Dr Scovy Mysteries
              </h2>
              <h3 className='font-bold text-lgl md:text-xl lg:text-2xl'>
                Solve. Exciting. Fun.
              </h3>
              <div className='my-2 text-center md:text-left'>
                <Link to={'/mysteries'}>
                  <button className='bg-green  rounded-xl font-bold text-base px-10 py-2 lg:text-lg md:px-8 md:py-3 '>
                    Start Earning
                  </button>
                </Link>
              </div>
            </div>
            <div className='relative xs:w-32 sm:w-40 md:w-48 lg:w-56'>
              <img
                src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/58aac47a-199f-4018-c523-5043ac146700/public'
                alt='duckimage'
                className='xs:w-32 sm:w-40 md:w-48 lg:w-56 md:absolute right-0  bottom-[-130px] mb-2 md:mb-4 lg:mb-2 -rotate-12'
              />
            </div>
          </div>
        </div>

        {/* Social Cards */}
        <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4 '>
          <div className='bg-cardBg rounded-lg py-4 px-8 md:py-8 md:pl-10 lg:pl-12 w-full'>
            <div className='flex flex-col justify-center items-center md:justify-start md:items-start gap-1'>
              <img
                src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/6f2da959-210a-4cef-f361-178ee31d0700/profile'
                alt='twitch_logo'
                className='w-12'
              />
              <h3 className='font-bold text-lgl md:text-xl lg:text-2xl truncate'>
                Visit the Pond
              </h3>
              <Link
                to='https://live.dashducks.tv'
                target='_blank'
                className='text-[#516BF2] font-bold text-base lg:text-xl hover:underline truncate'
              >
                DashDucks Livestream
              </Link>
            </div>
          </div>
          <div className='bg-cardBg rounded-lg py-4 px-8 md:py-8 md:pl-10 lg:pl-12 w-full h-full'>
            <div className='flex flex-col justify-center items-center md:justify-start md:items-start gap-1'>
              <img
                src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/9a85499e-8ac6-41ed-2ade-dc5266855e00/public'
                alt='twitch_logo'
                className='w-[3.5rem] '
              />
              <h3 className='font-bold text-lgl md:text-xl lg:text-2xl truncate'>
                Feed with Reposts
              </h3>
              <Link
                to='https://x.com/DuckFoodBot'
                target='_blank'
                className='text-[#516BF2] font-bold text-base lg:text-xl hover:underline truncate'
              >
                @DuckFoodBot
              </Link>
            </div>
          </div>
        </div>

        {/* Twitch Raids Card */}
        <div className=' bg-cardBg rounded-lg py-4 px-8 md:pt-8 md:pb-14 md:pl-10 lg:pl-12 md:pr-20 lg:pr-24 max-w-4xl'>
          <div className='flex flex-col-reverse justify-center items-center md:flex-row md:justify-between'>
            <div className='flex flex-col justify-center items-center md:justify-start md:items-start gap-1'>
              <h2 className='font-bold text-2xl md:text-3xl lg:text-4xl'>
                Twitch Raids
              </h2>
              <h3 className='font-bold text-lgl md:text-xl lg:text-2xl'>
                Free Tokens. Chat Party.
              </h3>
              <div className='my-2 text-center md:text-left'>
                <Link to={'/raids'}>
                  <button className='bg-green  rounded-xl font-bold text-base px-10 py-2 lg:text-lg md:px-8 md:py-3 '>
                    Raid Rewards
                  </button>
                </Link>
              </div>
            </div>
            <div className='relative xs:w-40 sm:w-44 md:w-48 lg:w-56'>
              <img
                src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/cbfe7ba0-3c83-4eac-ea21-dc5f6f2d6900/public'
                alt='raidImage'
                className='xs:w-40 sm:w-44 md:w-48 lg:w-56 md:absolute right-0 mb-2 md:mb-0 bottom-[-110px] lg:bottom-[-120px]'
              />
            </div>
          </div>
        </div>

        {/* Achievements Cards*/}
        <div className=' bg-cardBg rounded-lg py-4 px-8 md:pt-8 md:pb-8 md:pl-10 lg:pl-12 md:pr-20 lg:pr-24 max-w-4xl'>
          <div className='flex flex-col-reverse justify-center items-center md:flex-row md:justify-between'>
            <div className='flex flex-col justify-center items-center md:justify-start md:items-start gap-1'>
              <h2 className='font-bold text-2xl md:text-3xl lg:text-4xl'>
                Achievements
              </h2>
              <h3 className='font-bold text-lgl md:text-xl lg:text-2xl'>
                Hatch. Collect.
              </h3>
              <div className='my-2 text-center md:text-left'>
                <Link to={'/progress'}>
                  <button className='bg-green  rounded-xl font-bold text-base px-10 py-2 lg:text-lg md:px-8 md:py-3 '>
                    Your Nest
                  </button>
                </Link>
              </div>
            </div>
            <div className='relative xs:w-40 sm:w-44 md:w-48 lg:w-56'>
              <img
                src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/a6e10e0d-d82b-4e54-6724-0ced4a5ee400/public'
                alt='nestImage'
                className='xs:w-40 sm:w-44 md:w-48 lg:w-56 md:absolute right-0 mb-2 md:mb-0 bottom-[-64px] lg:bottom-[-80px]'
              />
            </div>
          </div>
        </div>

        {/* Photos Carousal Cards*/}
        <div className=' bg-cardBg rounded-lg py-4 px-8 md:pt-8 md:pb-8 md:pl-10 lg:pl-12 max-w-4xl'>
          <div className='flex flex-col-reverse justify-center items-center md:flex-row md:justify-between'>
            <div className='flex flex-col justify-center items-center md:justify-start md:items-start gap-1'>
              <h2 className='font-bold text-2xl md:text-3xl lg:text-4xl'>
                Photos
              </h2>
              <h3 className='font-bold text-lgl md:text-xl lg:text-2xl'>
                Share. Earn Tokens
              </h3>
              <div className='my-2 text-center md:text-left'>
                <Link to={'/photos'}>
                  <button className='bg-green  rounded-xl font-bold text-base px-10 py-2 lg:text-lg md:px-8 md:py-3 '>
                    Photos
                  </button>
                </Link>
              </div>
            </div>
            <div className='relative xs:w-60 sm:w-64 md:w-72 lg:w-80'>
              <img
                src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/96ccef1c-6ef9-400c-f52d-91529138e900/public'
                alt='nestImage'
                className='xs:w-60 sm:w-64  md:w-72 lg:w-80 md:absolute right-0 lg:right-4 bottom-[-72px] mb-2 md:mb-0'
              />
            </div>
          </div>
        </div>

        {/* Youtube / Links Card */}
        <div className=' bg-cardBg rounded-lg py-4 px-8 md:pt-8 md:pb-8 max-w-4xl'>
          {/* <div className='flex flex-col sm:flex-row sm:justify-around sm:items-center gap-4 '> */}
          <div className='flex flex-col justify-around items-center gap-8'>
            <div>
              <img
                src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/0d375876-6e9c-4028-a76c-67a701173b00/profile'
                alt='yt_white_logo'
                className='w-36'
              />
            </div>
            <div className='flex flex-col justify-around sm:flex-row sm:justify-around sm:items-center gap-4 w-full '>
              <div className='flex flex-col justify-center items-center gap-1 '>
                <img
                  src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/6929b911-81be-4fe0-d24c-1ddda5c3f600/profile'
                  alt='yt_logo'
                  className='w-12 sm:w-16 md:w-20'
                />
                <Link
                  to='https://www.youtube.com/@drscovy'
                  target='_blank'
                  className='text-[#516BF2] font-bold text-base lg:text-xl hover:underline '
                >
                  Dr Scovy Youtube
                </Link>
              </div>
              <div className='flex flex-col justify-center items-center gap-1 '>
                <img
                  src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/31f81596-8b52-403b-738f-ce429fd6ea00/profile'
                  alt='ducks_logo'
                  className='w-12 sm:w-16 md:w-20'
                />
                <Link
                  to='https://www.youtube.com/@DashDucks'
                  target='_blank'
                  className='text-[#516BF2] font-bold text-base lg:text-xl hover:underline '
                >
                  DashDucks Youtube
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default HomePagev2;
